import { Suspense } from 'react';
import Welcome from './servicePages/Welcome';
import WhatsNew from './servicePages/WhatsNew';
import ProActiveMaintenanceInfo from './functionPages/ProActiveMaintenanceInfo';
import BookingOptions from './functionPages/BookingOptions';
import WorkshopAssignment from './functionPages/WorkshopAssignment';
import DiagnosticsData from './functionPages/DiagnosticsData';
import ManageAppointments from './functionPages/ManageAppointments';
import DamagesInbox from './functionPages/DamagesInbox';
import SmartphoneTabletView from './functionPages/SmartphoneTabletView';
import { SetLastSeenToNow } from '../useStoredReleaseNotes';
import OverviewInfo from './functionPages/OverviewInfo';
import ExportDiagnosticData from './functionPages/ExportDiagnosticData';
import BatteryHealth from './eMobilityPages/BatteryHealth';
import AboutElectricVehicles from './eMobilityPages/AboutElectricVehicles';
import { FeedbackCareMfe } from '../../../microfrontends/FeedbackCareMfe';

export enum InfoDialogContentType {
    welcome = 'welcome',
    whatsNew = 'whats_new',
    feedback = 'feedback',
    booking_options = 'booking_options',
    overview = 'overview',
    workshopAssignment = 'workshop_assignment',
    diagnosticsData = 'diagnostics_data',
    manageAppointments = 'manage_appointments',
    proactiveMaintenance = 'proactivemaintenance',
    damagesInbox = 'damages_inbox',
    smartphoneTabletView = 'smartphone_tablet_view',
    exportDiagnosticData = 'export_diagnostics_data',
    aboutElectricVehicles = 'electric_vehicles',
    batteryHealth = 'battery_health',
}

type InfoDialogDisplaySectionProps = {
    section: InfoDialogContentType;
    setLastSeenToNow: SetLastSeenToNow;
    feedbackOrigin?: string;
};
const InfoDialogDisplaySection = ({ section, setLastSeenToNow, feedbackOrigin }: InfoDialogDisplaySectionProps) => {
    return <Suspense fallback="">{renderComponent()}</Suspense>;

    function renderComponent() {
        switch (section) {
            case InfoDialogContentType.welcome:
                return <Welcome />;
            case InfoDialogContentType.feedback:
                return <FeedbackCareMfe feedbackOrigin={feedbackOrigin} />;
            case InfoDialogContentType.whatsNew:
                return <WhatsNew setLastSeenToNow={setLastSeenToNow} />;
            case InfoDialogContentType.booking_options:
                return <BookingOptions />;
            case InfoDialogContentType.overview:
                return <OverviewInfo />;
            case InfoDialogContentType.workshopAssignment:
                return <WorkshopAssignment />;
            case InfoDialogContentType.diagnosticsData:
                return <DiagnosticsData />;
            case InfoDialogContentType.manageAppointments:
                return <ManageAppointments />;
            case InfoDialogContentType.proactiveMaintenance:
                return <ProActiveMaintenanceInfo />;
            case InfoDialogContentType.damagesInbox:
                return <DamagesInbox />;
            case InfoDialogContentType.smartphoneTabletView:
                return <SmartphoneTabletView />;
            case InfoDialogContentType.exportDiagnosticData:
                return <ExportDiagnosticData />;
            case InfoDialogContentType.aboutElectricVehicles:
                return <AboutElectricVehicles />;
            case InfoDialogContentType.batteryHealth:
                return <BatteryHealth />;
        }
    }
};
export default InfoDialogDisplaySection;
