import { WhatsNewImageId } from './WhatsNewImageImports';

export type StaticReleaseNote = {
    version: string;
    date: string;
    messages: Array<{
        title?: string;
        preTextKeys?: Array<string>;
        textItemKeys?: Array<string>;
        textFooterLink?: string;
        infoDialogContentTypeLink?: string;
        textFooterKeys?: Array<string>;
        imageId?: WhatsNewImageId;
        textAfterImageKeys?: Array<string>;
        orderedList?: boolean;
    }>;
    showPopup?: boolean;
};

export const staticReleaseNotes: Array<StaticReleaseNote> = [
    {
        version: '3.3.4',
        date: '2024-11-19',
        messages: [
            {
                title: 'intl-msg:fleetstatus.info.whats_new.release_node.3_3_4.title',
                preTextKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.3_3_4.pre_text_1', 'intl-msg:fleetstatus.info.whats_new.release_node.3_3_4.pre_text_2'],
            },
        ],
        showPopup: true,
    },
    {
        version: '3.3.3',
        date: '2024-11-06',
        messages: [
            {
                title: 'intl-msg:fleetstatus.info.whats_new.release_node.3_3_3.title',
                preTextKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.3_3_3.pre_text_1', 'intl-msg:fleetstatus.info.whats_new.release_node.3_3_3.pre_text_2'],
                textItemKeys: [
                    'intl-msg:fleetstatus.info.whats_new.release_node.3_3_3.item_1',
                    'intl-msg:fleetstatus.info.whats_new.release_node.3_3_3.item_2',
                    'intl-msg:fleetstatus.info.whats_new.release_node.3_3_3.item_3',
                    'intl-msg:fleetstatus.info.whats_new.release_node.3_3_3.item_4',
                    'intl-msg:fleetstatus.info.whats_new.release_node.3_3_3.item_5',
                    'intl-msg:fleetstatus.info.whats_new.release_node.3_3_3.item_6',
                ],
                imageId: 'DidEngineOilReleaseNoteImage',
                textAfterImageKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.3_3_3.text_after_image_1'],
            },
        ],
        showPopup: true,
    },
    {
        version: '3.3.2',
        date: '2024-01-22',
        messages: [
            {
                title: 'intl-msg:fleetstatus.info.whats_new.release_node.3_3_2.title',
                preTextKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.3_3_2.pre_text_1', 'intl-msg:fleetstatus.info.whats_new.release_node.3_3_2.pre_text_2'],
                textFooterKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.3_3_2.footer_text_1'],
                imageId: 'RioNotificationBellReleaseNote',
            },
        ],
        showPopup: true,
    },
    {
        version: '3.3.1',
        date: '2023-03-06',
        messages: [
            {
                title: 'intl-msg:fleetstatus.info.whats_new.release_node.3_3_1.title',
                preTextKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.3_3_1.pre_text_1'],
                imageId: 'CustomComponentWeeklyRecurrence',
            },
        ],
        showPopup: true,
    },
    {
        version: '3.3.0',
        date: '2023-02-07',
        messages: [
            {
                title: 'intl-msg:fleetstatus.info.whats_new.release_node.3_3_0.title',
                preTextKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.3_3_0.pre_text_1'],
                textFooterKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.3_3_0.footer_text_1'],
                imageId: 'HomeWorkshopReleaseNote',
            },
        ],
        showPopup: true,
    },
    {
        version: '3.2.0',
        date: '2022-12-15',
        messages: [
            {
                title: 'intl-msg:fleetstatus.info.whats_new.release_node.3_2_0.title',
                preTextKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.3_2_0.pre_text_1'],
                textFooterKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.3_2_0.footer_text_1'],
                imageId: 'DamageReportReleaseNote',
            },
        ],
        showPopup: true,
    },
    {
        version: '3.1.0',
        date: '2022-12-14',
        messages: [
            {
                title: 'intl-msg:fleetstatus.info.whats_new.release_node.3_1_0.title',
                preTextKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.3_1_0.pre_text_1'],
                textFooterKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.3_1_0.footer_text_1'],
                imageId: 'NotificationSettingsReleaseNotes',
            },
        ],
        showPopup: true,
    },
    {
        version: '3.0.0',
        date: '2022-09-20',
        messages: [
            {
                title: 'intl-msg:fleetstatus.info.whats_new.release_node.3_0_0.title',
                preTextKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.3_0_0.pre_text_1'],
                textFooterKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.3_0_0.footer_text_1'],
                imageId: 'TGEReleaseNote',
            },
        ],
        showPopup: true,
    },
    {
        version: '2.2.0',
        date: '2021-10-29',
        messages: [
            {
                title: 'intl-msg:fleetstatus.info.whats_new.release_node.2_2_0.title',
                preTextKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.2_2_0.pre_text_1', 'intl-msg:fleetstatus.info.whats_new.release_node.2_2_0.pre_text_2'],
                textFooterKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.2_2_0.footer_text_1'],
                textFooterLink: 'intl-msg:fleetstatus.info.whats_new.release_node.2_2_0.footer_link',
                infoDialogContentTypeLink: 'electric_vehicles',
                textItemKeys: [],
                imageId: 'E4CReleaseNotesPreview',
                orderedList: true,
            },
        ],
        showPopup: true,
    },
    {
        version: '2.1.0',
        date: '2021-10-07',
        messages: [
            {
                title: 'intl-msg:fleetstatus.info.whats_new.release_node.2_1_0.title',
                preTextKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.2_1_0.pre_text_1', 'intl-msg:fleetstatus.info.whats_new.release_node.2_1_0.pre_text_2'],
                textFooterKeys: [],
                textItemKeys: [
                    'intl-msg:fleetstatus.info.whats_new.release_node.2_1_0.item_1',
                    'intl-msg:fleetstatus.info.whats_new.release_node.2_1_0.item_2',
                    'intl-msg:fleetstatus.info.whats_new.release_node.2_1_0.item_3',
                ],
                imageId: 'DiagnosticsReleaseNotesPreview',
                orderedList: true,
            },
        ],
        showPopup: true,
    },
    {
        version: '2.0.0',
        date: '2021-06-08',
        messages: [
            {
                title: 'intl-msg:fleetstatus.info.whats_new.release_node.2_0_0.title',
                preTextKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.2_0_0.pre_text_1', 'intl-msg:fleetstatus.info.whats_new.release_node.2_0_0.pre_text_2'],
                textFooterKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.2_0_0.footer_text_1'],
                textItemKeys: [
                    'intl-msg:fleetstatus.info.whats_new.release_node.2_0_0.item_1',
                    'intl-msg:fleetstatus.info.whats_new.release_node.2_0_0.item_2',
                    'intl-msg:fleetstatus.info.whats_new.release_node.2_0_0.item_3',
                    'intl-msg:fleetstatus.info.whats_new.release_node.2_0_0.item_4',
                    'intl-msg:fleetstatus.info.whats_new.release_node.2_0_0.item_5',
                    'intl-msg:fleetstatus.info.whats_new.release_node.2_0_0.item_6',
                ],
                textFooterLink: 'intl-msg:fleetstatus.global.click_here',
                infoDialogContentTypeLink: 'overview',
                imageId: 'OverviewInfo',
                orderedList: true,
            },
        ],
    },
    {
        version: '1.1.4',
        date: '2021-04-27',
        messages: [
            {
                title: 'intl-msg:fleetstatus.info.whats_new.release_node.1_1_4.title',
                preTextKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.1_1_4.pre_text_1', 'intl-msg:fleetstatus.info.whats_new.release_node.1_1_4.pre_text_2'],
                textFooterKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.1_1_4.footer_text_1'],
                imageId: 'SolvedByCustomer',
            },
        ],
    },
    {
        version: '1.1.3',
        date: '2021-03-19',
        messages: [
            {
                title: 'intl-msg:fleetstatus.info.whats_new.release_node.1_1_3.title',
                preTextKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.1_1_3.pre_text_1', 'intl-msg:fleetstatus.info.whats_new.release_node.1_1_3.pre_text_2'],
                textFooterKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.1_1_3.footer_text_1'],
                textFooterLink: 'intl-msg:fleetstatus.global.share_feedback',
                infoDialogContentTypeLink: 'feedback',
                imageId: 'MultiProactiveMaintenance',
            },
        ],
    },
    {
        version: '1.1.2',
        date: '2021-03-09',
        messages: [
            {
                title: 'intl-msg:fleetstatus.info.whats_new.release_node.1_1_2.title',
                preTextKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.1_1_2.pre_text_1', 'intl-msg:fleetstatus.info.whats_new.release_node.1_1_2.pre_text_2'],
                textItemKeys: [
                    'intl-msg:fleetstatus.info.whats_new.release_node.1_1_2.item_1',
                    'intl-msg:fleetstatus.info.whats_new.release_node.1_1_2.item_2',
                    'intl-msg:fleetstatus.info.whats_new.release_node.1_1_2.item_3',
                ],
                textFooterKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.1_1_2.footer_text_1', 'intl-msg:fleetstatus.info.whats_new.release_node.1_1_2.footer_text_2'],
            },
        ],
    },
    {
        version: '1.1.1',
        date: '2021-03-02',
        messages: [
            {
                title: 'intl-msg:fleetstatus.info.whats_new.release_node.1_1_1.title',
                preTextKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.1_1_1.pre_text_1', 'intl-msg:fleetstatus.info.whats_new.release_node.1_1_1.pre_text_2'],
                textFooterKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.1_1_1.footer_text_1', 'intl-msg:fleetstatus.info.whats_new.release_node.1_1_1.footer_text_2'],
                imageId: 'Threshold',
            },
        ],
    },
    {
        version: '1.1.0',
        date: '2021-02-24',
        messages: [
            {
                title: 'intl-msg:fleetstatus.info.whats_new.release_node.1_1_0.title',
                preTextKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.1_1_0.pre_text_1', 'intl-msg:fleetstatus.info.whats_new.release_node.1_1_0.pre_text_2'],
                textItemKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.1_1_0.item_1', 'intl-msg:fleetstatus.info.whats_new.release_node.1_1_0.item_2'],
                imageId: 'ReleaseNotesMultiSelection',
                orderedList: true,
            },
        ],
    },
    {
        version: '1.0.0',
        date: '2021-01-25',
        messages: [
            {
                preTextKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.1_0_0.pre_text_1', 'intl-msg:fleetstatus.info.whats_new.release_node.1_0_0.pre_text_2'],
                textItemKeys: [
                    'intl-msg:fleetstatus.info.whats_new.release_node.1_0_0.item_1',
                    'intl-msg:fleetstatus.info.whats_new.release_node.1_0_0.item_2',
                    'intl-msg:fleetstatus.info.whats_new.release_node.1_0_0.item_3',
                    'intl-msg:fleetstatus.info.whats_new.release_node.1_0_0.item_4',
                    'intl-msg:fleetstatus.info.whats_new.release_node.1_0_0.item_5',
                    'intl-msg:fleetstatus.info.whats_new.release_node.1_0_0.item_6',
                    'intl-msg:fleetstatus.info.whats_new.release_node.1_0_0.item_7',
                ],
                textFooterKeys: ['intl-msg:fleetstatus.info.whats_new.release_node.1_0_0.footer_text_1'],
            },
        ],
    },
];
