import IframeResizer from 'iframe-resizer-react';
import { FEEDBACK_CARE_MFE, FEEDBACK_CARE_MFE_LOCAL_URL, FEEDBACK_CARE_MFE_PROD_URL } from './microfrontendsConfig';
import { memo } from 'react';

export const enum FeedbackOriginEnum {
    ENGINE_OIL = 'CUSTOMER_ENGINE_OIL',
    GENERAL = 'CUSTOMER',
}

type FeedbackCareMfeProps = {
    feedbackOrigin: string | undefined;
};
export const FeedbackCareMfe = memo(({ feedbackOrigin }: FeedbackCareMfeProps) => {
    return (
        <IframeResizer
            src={FEEDBACK_CARE_MFE.getMfeUrl(FEEDBACK_CARE_MFE_LOCAL_URL, FEEDBACK_CARE_MFE_PROD_URL, '#feedback', { feedback_origin: feedbackOrigin })}
            checkOrigin={false}
            style={{ width: '1px', minWidth: '100%', border: '0', minHeight: '200px' }}
        />
    );
});
