import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { InfoDialogContentType } from './info/InfoDialogDisplaySection';
import { getSearchParamsString } from './info/Info';
import { FeedbackOriginEnum } from '../../microfrontends/FeedbackCareMfe';

type LinkSearchParamsStrings = {
    [InfoDialogContentType.welcome]: string;
    [InfoDialogContentType.whatsNew]: string;
    [InfoDialogContentType.feedback]: string;
    [InfoDialogContentType.booking_options]: string;
};

type ServiceInfoProps = {
    notSeenReleaseNotes: number;
};

const ServiceInfo = ({ notSeenReleaseNotes }: ServiceInfoProps) => {
    const location = useLocation();

    // set state and initial values
    const [linkSearchParamsStrings, setLinkSearchParamsStrings] = useState<LinkSearchParamsStrings>({
        [InfoDialogContentType.welcome]: getSearchParamsString(location.search, InfoDialogContentType.welcome),
        [InfoDialogContentType.whatsNew]: getSearchParamsString(location.search, InfoDialogContentType.whatsNew),
        [InfoDialogContentType.feedback]: getSearchParamsString(location.search, InfoDialogContentType.feedback, FeedbackOriginEnum.GENERAL),
        [InfoDialogContentType.booking_options]: getSearchParamsString(location.search, InfoDialogContentType.booking_options),
    });

    useEffect(() => {
        // sets state when history/location changes
        setLinkSearchParamsStrings({
            [InfoDialogContentType.welcome]: getSearchParamsString(location.search, InfoDialogContentType.welcome),
            [InfoDialogContentType.whatsNew]: getSearchParamsString(location.search, InfoDialogContentType.whatsNew),
            [InfoDialogContentType.feedback]: getSearchParamsString(location.search, InfoDialogContentType.feedback, FeedbackOriginEnum.GENERAL),
            [InfoDialogContentType.booking_options]: getSearchParamsString(location.search, InfoDialogContentType.booking_options),
        });
    }, [location]);

    return (
        <div>
            <div className="line-height-largest">
                <span className="rioglyph rioglyph-hand-right margin-right-5" />
                <Link to={{ search: linkSearchParamsStrings[InfoDialogContentType.welcome] }}>
                    <FormattedMessage id={'intl-msg:fleetstatus.info.welcome'} />
                </Link>
            </div>
            <div className="line-height-largest">
                <span className="rioglyph rioglyph-exclamation-sign margin-right-5" />
                <Link to={{ search: linkSearchParamsStrings[InfoDialogContentType.whatsNew] }}>
                    <FormattedMessage id={'intl-msg:fleetstatus.info.whats_new'} />
                </Link>
                {notSeenReleaseNotes ? <span className="badge bg-primary scale-80 align-top text-size-12">{notSeenReleaseNotes}</span> : null}
            </div>
            <div className="line-height-largest">
                <span className="rioglyph rioglyph-comment margin-right-5" />
                <Link to={{ search: linkSearchParamsStrings[InfoDialogContentType.feedback] }}>
                    <FormattedMessage id={'intl-msg:fleetstatus.info.feedback'} />
                </Link>
            </div>
            <div className="line-height-largest">
                <span className="rioglyph rioglyph-question-sign margin-right-5" />
                <Link to={{ search: linkSearchParamsStrings[InfoDialogContentType.booking_options] }}>
                    <FormattedMessage id={'intl-msg:fleetstatus.info.functions'} />
                </Link>
            </div>
        </div>
    );
};

export default ServiceInfo;
